.sc-cart {
    &-item {
        &:hover {
            .sc-cart-item-title {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                    color: $linksColor;
                }
            }
        }

        & + & {
            margin-top: 1rem;

            @include media-breakpoint-up(md) {
                margin-top: 1.5rem;
            }
        }
    }

    .sc-buy-one-click {
        margin-top: 1rem;

        @include media-breakpoint-up(sm) {
            justify-content: flex-end !important;
            padding: 0 !important;
        }

        @include media-breakpoint-up(md) {
            margin-top: 1.5rem;
        }

        &-title {
            @include media-breakpoint-down(sm) {
                padding-right: 0;
                padding-bottom: 1rem;
            }

            @include media-breakpoint-up(sm) {
                flex-grow: 0 !important;
            }
        }

        @include media-breakpoint-up(sm) {
            input[name="telephone"] {
                max-width: 200px;
            }
        }

        .input-group {
            width: auto !important;
        }
    }

    &-actions {
        @include media-breakpoint-up(lg) {
            z-index: 100;
            top: 4.625rem;
            position: sticky;
        }

        &-block {
            border-bottom: $mainBorder;
            padding: 1rem 0;

            &-title {
                cursor: pointer;
                text-decoration: underline;

                &:after {
                    content: "";
                    display: inline-block;
                    mask: url(../img/sprite.svg#include--input-sort);
                    mask-size: cover;
                    margin-left: 0.5rem;
                    flex: 0 0 8px;
                    max-width: 8px;
                    width: 8px;
                    height: 6px;
                    background: $primaryDarkColor;
                    transition: transform $mainTransition;
                }

                &.clicked {
                    text-decoration: none;

                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }

            &-group {
                max-height: 0;
                overflow: hidden;
                transition: max-height $mainTransition, padding-top $mainTransition;

                &.opened {
                    max-height: 1000px;
                    padding-top: 1rem;
                }

                @include media-breakpoint-down(sm) {
                    .form-control {
                        width: 100%;
                        border-top-right-radius: 0.25rem !important;
                        border-bottom-right-radius: 0.25rem !important;
                    }

                    .sc-btn {
                        width: 100%;
                        margin-top: 1rem;
                        border-radius: 0.25rem;
                    }
                }

                .sc-input-with-btn {
                    box-shadow: none;
                    filter: none;
                    width: auto;

                    @include media-breakpoint-up(md) {
                        border-top-right-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }

                .form-group label {
                    flex: 0 0 200px;
                }
            }
        }
    }
}

.oct-checkout-actions {
    .sc-cart-actions-block-group {
        .sc-btn,
        input[type=text],
        select {
            width: 100%;
        }

        .sc-btn {
            margin-top: 1rem;
            height: 48px !important;
        }
    }

    input[type="text"] {
        width: 100%;
        max-width: 100%;
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }

    .sc-input-with-btn {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
    }

    .sc-input-with-btn,
    .input-group-btn,
    .input-group {
        width: 100%;
    }
}